import React from "react";
import { Link } from "gatsby";
import Helmet from "react-helmet";
import Layout from "../components/layout";
import BannerLanding from "../components/BannerLanding";

import pic08 from "../assets/images/oud.jpeg";
import pic09 from "../assets/images/pic09.jpg";
import pic10 from "../assets/images/pic10.jpg";

const Oud = props => (
  <Layout>
    <Helmet
      title="All About Oud Instrument"
      meta={[
        {
          name: "description",
          content: "Learn about the history of oud."
        },
        { name: "keywords", content: "oud, music, lesson, online" }
      ]}
    ></Helmet>

    <BannerLanding />

    <div id="main">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h2>Oud</h2>
          </header>
          <p>
            Oud (name is originally Arabic) seems to find its roots in the
            Akkadian era. Some believe it originated in the Caucasus region,
            other sources report more western regions as a point of origin. Some
            findings show an early elliptical design of Oud found in the ruins
            of Shush, Iran, stretching its history to some five thousand years
            ago. Some experts theorize that a priest is shown to be playing the
            instrument in that depiction.
          </p>
          <p>
            Barbat is the Iranian/Persian name for the same instrument and it’s
            the name that can be found in pre-islamic texts, referring to the
            most prominent musical instrument of the Sassanid era.
          </p>
          <p>
            Some say that the word Barbat consists of two parts: Bar + bat:
            Meaning “in likeness of a duck”.
          </p>
          <p>
            Others theorize the name is a variation of Barbod, player of ancient
            Barbat (Roud) in the court of Khosrow Parviz, the most recognized
            Sassanid king for his appreciation of music and musicians.
          </p>
          <p>
            What we know for certain is that after Islam flourished through this
            part of the world, the more or less unified or at least connected
            Islamic territory had Oud as the name of the instrument, and it was
            considered the most prominent musical instrument both in theory and
            practice of music.
          </p>
          <p>
            Oud as now we see in the world, comes in different sizes. Arabic
            Ouds have bigger b odies, Turkish ones are mostly smaller, and
            Persian Ouds usually fall in between. Oud’s warm and fluid sound
            makes it a versatile instrument in solo performances and in
            showcasing the nuances of Persian music. Oud is low-pitched in
            nature, and the traditional 10-string Oud has a limited range of two
            octaves, therefore the player has to master the intricacy to
            showcase its delicate beauty.
          </p>
          <p>
            The Oud consists of a large pear-shaped body (or soundbox) attached
            to a short neck. The front (or face) of the Oud's body is flat and
            contains one or more sound-holes, whereas the back is bowl-shaped
            and constructed from around twenty thin strips of wood known as
            'ribs'. Traditionally, strings are attached to a bridge on the face
            of the Oud (similar to a guitar) and pass over a nut at the other
            end of the instrument. There can also be a floating bridge instead
            that provides the opportunity to move it a bit and get a different
            sound of the same instrument. Tuning is achieved by turning a series
            of pegs contained in a pegbox, which is set almost at a right angle
            to the neck of the Oud. Notes other than open strings are produced
            by stopping the strings at certain points on the front of the neck;
            this is called “fingering” - so, the area is also known as the
            fingerboard.
          </p>
        </div>
      </section>
      <section id="two" className="spotlights">
        <section>
          <Link
            to="https://drive.google.com/drive/folders/1rJEoJPgyvcWWbSofpSjUhIfrXg2DFpm4?usp=sharing"
            className="image"
          >
            <img src={pic08} alt="" />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Online Lessons</h3>
              </header>
              <p>
                I have been teaching oud to people professionally since 1993. I
                teach to all skill levels, from basics for beginners to master
                classes for experts. My repertoire includes music Turkey, Egypt,
                Jordan, Iraq, Syria, and western music. Join my classes today!
              </p>
              <ul className="actions">
                <li>
                  <Link to="/lessons" className="button" target="_blank">
                    Oud Lessons
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </section>
    </div>
  </Layout>
);

export default Oud;
